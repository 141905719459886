export const PAGINATION_PAGE_LIMIT: number = 4;
export const PAGINATION_PAGE_MAX_ITEMS: number = 25;

const createPageRange = (min: number, max: number): Array<number> => {
  const length = max - min + 1;
  const array = [length];
  for (let i = 0; i < length; i++) {
    array[i] = min + i;
  }
  return array;
};

export const createPages = (
  totalPages: number,
  selectedPage: number,
): number[] => {
  let minPage = Math.ceil(selectedPage - PAGINATION_PAGE_LIMIT / 2);
  let maxPage = Math.ceil(selectedPage + PAGINATION_PAGE_LIMIT / 2);
  if (minPage <= 0) {
    minPage = 1;
  }
  if (maxPage > totalPages) {
    maxPage = totalPages;
  }
  return minPage <= maxPage ? createPageRange(minPage, maxPage) : [1];
};

import { GeoJSONSource, Map } from "mapbox-gl";

import type { MapCoordinates } from "@types";

/* VARIABLES */

let userLocation: MapCoordinates;

/* SETTERS & GETTERS */

export const getUserLocation = (): typeof userLocation => userLocation;
export const setUserLocation = (
  updatedUserLocation: typeof userLocation,
): typeof userLocation => (userLocation = updatedUserLocation);

/* ADD */

export const addMapGeolocation = (map: Map): number | undefined => {
  if (navigator.geolocation) {
    const minFrequency = 10 * 1000;
    let lastUpdateTime: Date | undefined = undefined;
    return navigator.geolocation.watchPosition(
      (geolocationPosition: GeolocationPosition) => {
        const now = new Date();
        if (
          lastUpdateTime &&
          now.getTime() - lastUpdateTime.getTime() < minFrequency
        ) {
          return;
        }

        userLocation = [
          geolocationPosition.coords.longitude,
          geolocationPosition.coords.latitude,
        ];
        updateUserLocationMarker(map);

        lastUpdateTime = now;
      },
      null,
      { timeout: 5 * 60 * 1000, enableHighAccuracy: true },
    );
  }
};

/* UPDATE */

export const updateUserLocationMarker = (map: Map): void => {
  const source: GeoJSONSource | undefined = map.getSource("user-coordinates");
  if (source) {
    source.setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: userLocation,
          },
          properties: {},
        },
      ],
    });
  } else {
    map.addSource("user-coordinates", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: userLocation,
            },
            properties: {},
          },
        ],
      },
    });

    map.addLayer({
      id: "user-coordinates-radius",
      source: "user-coordinates",
      type: "circle",
      paint: {
        "circle-opacity": 0.25,
        "circle-color": "#24446a",
        "circle-radius": 20,
      },
    });

    map.addLayer({
      id: "user-coordinates-point",
      source: "user-coordinates",
      type: "circle",
      paint: {
        "circle-color": "#24446a",
        "circle-stroke-width": 3,
        "circle-stroke-color": "#ffffff",
        "circle-radius": 6,
      },
    });
  }
};

import { Map } from "mapbox-gl";

import { ToastType } from "@hooks/use-toast";
import type { MapCoordinates, Translation } from "@types";

import { updateMapRoute } from "./map";
import { getRouteInfo } from "./map";
import {
  addMapGeolocation,
  getUserLocation,
  updateUserLocationMarker,
} from "./map/geolocation";
import {
  flyToMap,
  flyToUserLocation,
  fullscreenMap,
  zoomInMap,
  zoomOutMap,
} from "./map/utils";

export * from "./map/geolocation";
export * from "./map/marker";
export * from "./map/route";
export * from "./map/styles";
export * from "./map/utils";

export const addMapLoad = (
  map: Map,
  t: Translation,
  toast: ToastType,
  setMapLoaded: () => void,
): (() => void) => {
  let geolocationID: number | undefined;

  map.on("load", () => {
    geolocationID = addMapGeolocation(map);
  });
  map.on("style.load", () => {
    const routeInfo = getRouteInfo();
    const userLocation = getUserLocation();
    routeInfo && updateMapRoute(map, routeInfo.route, routeInfo.tracker);
    userLocation && updateUserLocationMarker(map);
    setMapLoaded();
  });

  const onFlyMap = (event: CustomEvent): void => {
    const coordinates: MapCoordinates = event.detail.coordinates;
    flyToMap(map, coordinates);
  };

  const onZoomIn = (): void => {
    zoomInMap(map);
  };
  const onZoomOut = (): void => {
    zoomOutMap(map);
  };
  const onUserLocation = (): void => {
    flyToUserLocation(map, t, toast);
  };
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onFullscreen = (event: CustomEvent): void => {
    fullscreenMap(event.detail.isFullscreen);
  };

  document.addEventListener("onFlyToMap", onFlyMap as EventListener);
  document.addEventListener("onZoomIn", onZoomIn);
  document.addEventListener("onZoomOut", onZoomOut);
  document.addEventListener("onUserLocation", onUserLocation);
  document.addEventListener("onFullscreen", onFullscreen as EventListener);

  return () => {
    navigator?.geolocation &&
      geolocationID &&
      navigator.geolocation.clearWatch(geolocationID);
    document.removeEventListener("onFlyToMap", onFlyMap as EventListener);
    document.removeEventListener("onZoomIn", onZoomIn);
    document.removeEventListener("onZoomOut", onZoomOut);
    document.removeEventListener("onUserLocation", onUserLocation);
    document.removeEventListener("onFullscreen", onFullscreen as EventListener);
  };
};

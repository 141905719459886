import { GeoJSONSource, Map } from "mapbox-gl";

import type { MapCoordinates, MapRoute, Tracker, Tracking } from "@types";
import { getCoordinatesDistance } from "@utils/tracker";

/* CONSTANTS */

export const MAX_CLOSEST_DISTANCE = 30;

/* VARIABLES */

let routeInfo: { tracker: Tracker; route: MapRoute };
let routesAnimationId: number | undefined;

/* SETTERS & GETTERS */

export const getRouteInfo = (): typeof routeInfo => routeInfo;
export const setRouteInfo = (
  updatedRouteInfo: typeof routeInfo,
): typeof routeInfo => (routeInfo = updatedRouteInfo);

/* GET */

export const getMapRoute = (tracking: Tracking): Array<MapCoordinates> => {
  const routeCoordinates: Array<MapCoordinates> = [];

  for (let index = 0; index < tracking.history.length; index++) {
    const coordinates: MapCoordinates =
      tracking.history[index].location.coordinates;

    if (index === 0) {
      routeCoordinates.push(coordinates);
    } else {
      const distance: number = getCoordinatesDistance(
        coordinates,
        tracking.history[index - 1].location.coordinates,
      );
      if (distance >= MAX_CLOSEST_DISTANCE) {
        routeCoordinates.push(coordinates);
      }
    }
  }

  return routeCoordinates;
};

/* ADD */

export const addMapRoute = (
  map: Map,
  route: MapRoute,
  tracker: Tracker,
): void => {
  const source: GeoJSONSource | undefined = map.getSource("route-line");
  routeInfo = { route, tracker };
  if (source) {
    source.setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            coordinates: route,
            type: "LineString",
          },
          properties: {},
        },
      ],
    });
  } else {
    map.addSource("route-line", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: route,
              type: "LineString",
            },
          },
        ],
      },
    });

    map.addLayer({
      type: "line",
      source: "route-line",
      id: "route-line-background",
      paint: {
        "line-color": tracker.marker.color,
        "line-width": 6,
        "line-opacity": 0.25,
      },
    });

    map.addLayer({
      type: "line",
      source: "route-line",
      id: "route-line-dashed",
      paint: {
        "line-color": tracker.marker.color,
        "line-width": 6,
        "line-dasharray": [0, 4, 3],
      },
    });

    const dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5],
    ];

    let step = 0;

    const animateDashArray = (timestamp: number): void => {
      const newStep = Number.parseInt(
        String((timestamp / 50) % dashArraySequence.length),
      );

      if (newStep !== step) {
        map.setPaintProperty(
          "route-line-dashed",
          "line-dasharray",
          dashArraySequence[step],
        );
        step = newStep;
      }

      routesAnimationId = requestAnimationFrame(animateDashArray);
    };

    animateDashArray(0);
  }
};

/* UPDATE */

export const updateMapRoute = (
  map: Map,
  route: MapRoute,
  tracker?: Tracker,
): void => {
  removeMapRoutes(map);
  tracker && addMapRoute(map, route, tracker);
};

/* REMOVE */

const removeMapRoutes = (map: Map): void => {
  map.getLayer("route-line-background") &&
    map.removeLayer("route-line-background");
  map.getLayer("route-line-dashed") && map.removeLayer("route-line-dashed");
  map.getSource("route-line") && map.removeSource("route-line");

  routesAnimationId && cancelAnimationFrame(routesAnimationId);
  routesAnimationId = undefined;
};

export const removeRouteAnimation = (): void => {
  routesAnimationId && cancelAnimationFrame(routesAnimationId);
  routesAnimationId = undefined;
};

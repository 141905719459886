import { Map, Marker } from "mapbox-gl";

import type {
  MapMarkers,
  MapMarkerType,
  Tracker,
  Tracking,
  TrackingHistory,
  Trackings,
  Translation,
} from "@types";
import { isTextLight } from "@utils/resource";
import {
  getCoordinatesDistance,
  getCurrentTrackerHistory,
} from "@utils/tracker";

import { MAX_CLOSEST_DISTANCE } from "../route";

/* VARIABLES */

const markers: Array<Marker> = [];

/* GET */

export const getMapMarkers = (tracking: Tracking): MapMarkers => {
  const markersHistory: Array<Array<TrackingHistory>> = [];
  for (let index = 0; index < tracking.history.length; index++) {
    const trackingHistory: TrackingHistory = tracking.history[index];

    if (index === 0) {
      markersHistory.push([trackingHistory]);
    } else {
      const distance: number = getCoordinatesDistance(
        trackingHistory.location.coordinates,
        tracking.history[index - 1].location.coordinates,
      );
      if (distance >= MAX_CLOSEST_DISTANCE) {
        markersHistory.push([trackingHistory]);
      } else {
        markersHistory.at(-1)?.push(trackingHistory);
      }
    }
  }

  return markersHistory.map((markerHistory) => ({
    main: markerHistory.at(-1)!,
    near: markerHistory.slice(0, -1),
  }));
};

/* ADD */

export const addMapMarkers = ({
  map,
  trackings,
  type,
  t,
}: {
  map: Map;
  trackings: Trackings;
  type: MapMarkerType;
  t: Translation;
}): void => {
  for (const tracking of trackings) {
    if (type === "primary") {
      const history: TrackingHistory | undefined = getCurrentTrackerHistory(
        tracking.history,
      );
      history &&
        addMapMarker({
          map,
          history,
          tracker: tracking.tracker,
          type,
          t,
        });
    } else {
      for (const [index, marker] of getMapMarkers(tracking).entries()) {
        addMapMarker({
          map,
          history: marker.main,
          tracker: tracking.tracker,
          type,
          index,
          t,
        });
      }
    }
  }
};

export const addMapMarker = ({
  map,
  history,
  tracker,
  type,
  index: _index,
  t,
}: {
  map: Map;
  history: TrackingHistory;
  tracker: Tracker;
  type: MapMarkerType;
  index?: number;
  t: Translation;
}): void => {
  const markerDiv: HTMLDivElement = document.createElement("div");
  markerDiv.className = `marker ${type}`;

  const markerImage: HTMLImageElement = document.createElement("img");
  markerImage.src = `/api/app/resource/marker?name=${type === "secondary" ? "secondary" : tracker.marker.type}&color=${encodeURIComponent(tracker.marker.color)}`;

  if (type === "secondary") {
    const markerSpanWrapper: HTMLSpanElement = document.createElement("div");
    markerSpanWrapper.className = "wrapper";

    const markerSpan: HTMLSpanElement = document.createElement("span");
    //TODO: FOR v0.2  markerSpan.textContent = (index + 1).toString();
    if (isTextLight(tracker.marker.color)) {
      markerSpan.className = "light";
    }

    markerImage.width = 50;
    markerImage.height = 50;
    markerImage.alt = t("common.map.historyMarker");
    markerSpanWrapper.append(markerSpan);
    markerDiv.append(markerImage, markerSpanWrapper);
  } else {
    markerImage.height = 75;
    markerImage.alt = t("common.map.trackerMarker");
    markerImage.style.rotate = `${history.direction}deg`;
    markerDiv.append(markerImage);
  }

  //TODO: FOR v0.2
  // markerDiv.addEventListener("click", () => {
  //   alert(history.direction);
  // });

  markers.push(
    new Marker(markerDiv)
      .setLngLat(history.location.coordinates)
      .setPopup()
      .addTo(map),
  );
};

/* UPDATE */

export const updateMapMarkers = ({
  map,
  type,
  trackings,
  t,
}: {
  map: Map;
  type: MapMarkerType;
  t: Translation;
  trackings?: Trackings;
}): void => {
  removeMapMarkers();
  trackings && addMapMarkers({ map, trackings, type, t });
};

/* REMOVE */

const removeMapMarkers = (): void => {
  for (const marker of markers) {
    marker.remove();
  }
};

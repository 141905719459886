import { Map } from "mapbox-gl";

import { ToastType } from "@hooks/use-toast";
import type { MapCoordinates, Translation } from "@types";

import { getUserLocation } from "../geolocation";

export const flyToMap = (map: Map, coordinates: MapCoordinates): void => {
  map.flyTo({ center: coordinates, zoom: 15 });
};

export const flyToUserLocation = (
  map: Map,
  t: Translation,
  toast: ToastType,
): void => {
  const userLocation = getUserLocation();
  if (userLocation) {
    flyToMap(map, userLocation);
  } else {
    toast({
      title: t("common.toast.locationTitle"),
      description: t("common.toast.locationDescription"),
      variant: "destructive",
    });
  }
};

export const zoomInMap = (map: Map): void => {
  map.zoomIn();
};

export const zoomOutMap = (map: Map): void => {
  map.zoomOut();
};

export const fullscreenMap = (isFullscreen: boolean): void => {
  const container = document.querySelector("body")!;

  if (isFullscreen) {
    if ((document as Document).exitFullscreen) {
      (document as Document).exitFullscreen();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((document as any)?.webkitCancelFullScreen) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document as any).webkitCancelFullScreen();
    }
  } else {
    if (container.requestFullscreen) {
      container.requestFullscreen();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((container as any).webkitRequestFullscreen) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (container as any).webkitRequestFullscreen();
    }
  }
};

const DEFAULT_RESOURCE_COLOR: string = "#dededb";

export const replaceResourceColor = (
  resource: Buffer,
  color: string,
): Buffer => {
  const index = resource.indexOf(DEFAULT_RESOURCE_COLOR);

  return Buffer.concat([
    resource.subarray(0, index),
    Buffer.from(color),
    resource.subarray(index + DEFAULT_RESOURCE_COLOR.length, resource.length),
  ]);
};

export const isValidHexColor = (color: string): boolean => {
  const hexRegex: RegExp = /^#([\da-f]{3}){1,2}$/i;
  return hexRegex.test(color);
};

export const isTextLight = (background: string): boolean => {
  const color =
    background.charAt(0) === "#" ? background.slice(1, 7) : background;
  const r = Number.parseInt(color.slice(0, 2), 16);
  const g = Number.parseInt(color.slice(2, 4), 16);
  const b = Number.parseInt(color.slice(4, 6), 16);
  const colors = [r / 255, g / 255, b / 255];
  const c = colors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L <= 0.179;
};

import type {
  MapCoordinates,
  TrackerType,
  TrackingHistory,
  Trackings,
} from "@types";

export const getCurrentTrackersHistory = (
  trackings: Trackings,
): TrackingHistory | undefined => {
  if (trackings && trackings.length > 0) {
    return trackings.find((tracking) =>
      getCurrentTrackerHistory(tracking.history),
    )?.history[0];
  }
  return undefined;
};

export const getCurrentTrackerHistory = (
  history: TrackingHistory[],
): TrackingHistory | undefined =>
  history && history.length > 0 ? history[0] : undefined;

export const isTrackerActive = (date: number): boolean =>
  Date.now() - new Date(date).getTime() <= 5 * 60 * 1000;

export const isSpeedOverLimit = (speed: number): boolean => speed > 120;

export const getCoordinatesDistance = (
  coord1: MapCoordinates,
  coord2: MapCoordinates,
): number => {
  const r = 6371;
  const p = Math.PI / 180;

  const a =
    0.5 -
    Math.cos((coord2[0] - coord1[0]) * p) / 2 +
    (Math.cos(coord1[0] * p) *
      Math.cos(coord2[0] * p) *
      (1 - Math.cos((coord2[1] - coord1[1]) * p))) /
      2;

  return 2 * r * Math.asin(Math.sqrt(a)) * 1000;
};

export const getTrackerTypeImageURL = (type: TrackerType): string => {
  switch (type) {
    case "car": {
      return "/images/vehicles/car.png";
    }
    case "truck": {
      return "/images/vehicles/truck.png";
    }
    default: {
      return "/images/vehicles/car.png";
    }
  }
};

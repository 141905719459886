import { Map } from "mapbox-gl";

import type { Locale, MapType, Translation } from "@types";

import { removeRouteAnimation } from "../route";

/* GET */

export const getMapStyle = (
  mapType: MapType,
  locale: Locale = "en",
  theme?: string,
): string => {
  switch (mapType) {
    case "navigation": {
      if (locale === "pt") {
        return theme === "dark"
          ? "mapbox://styles/mynameismang/clywv59lh009a01ph4oj546u1"
          : "mapbox://styles/mynameismang/clywv17u1008u01p64y1ubafz";
      } else {
        return theme === "dark"
          ? "mapbox://styles/mynameismang/clywuy8rg008p01qn1e384ech"
          : "mapbox://styles/mynameismang/clywuvkwz008o01qn99gw7rwz";
      }
    }
    case "streets": {
      return locale === "pt"
        ? "mapbox://styles/mynameismang/clywtzqwx008y01r03jdw7oyk"
        : "mapbox://styles/mynameismang/clywtkv3w008d01r28ciy5zgw";
    }
    case "satellite": {
      return locale === "pt"
        ? "mapbox://styles/mynameismang/clywvc9tq008p01pfalnv92fm"
        : "mapbox://styles/mynameismang/clywvbees009901r05qoo778t";
    }
    case "outdoors": {
      return locale === "pt"
        ? "mapbox://styles/mynameismang/clywvg897008001pc528h59kp"
        : "mapbox://styles/mynameismang/clywvfqx8007z01pc5f4jhdi1";
    }
    case "monochrome": {
      if (locale === "pt") {
        return theme === "dark"
          ? "mapbox://styles/mynameismang/clywvqoej008f01nv8ej523gf"
          : "mapbox://styles/mynameismang/clywvsikb008201pc0hk03ppa";
      } else {
        return theme === "dark"
          ? "mapbox://styles/mynameismang/clywvp3vs008n01r25um85m27"
          : "mapbox://styles/mynameismang/clywvj88i008m01r2bfmg2g1g";
      }
    }
    default: {
      return "mapbox://styles/mynameismang/clywuvkwz008o01qn99gw7rwz";
    }
  }
};

export const getMapLocale = (t: Translation): { [key: string]: string } => ({
  "AttributionControl.ToggleAttribution": t(
    "common.map.attributionControlToggleAttribution",
  ),
  "AttributionControl.MapFeedback": t(
    "common.map.attributionControlMapFeedback",
  ),
  "FullscreenControl.Enter": t("common.map.fullscreenControlEnter"),
  "FullscreenControl.Exit": t("common.map.fullscreenControlExit"),
  "GeolocateControl.FindMyLocation": t(
    "common.map.geolocateControlFindMyLocation",
  ),
  "GeolocateControl.LocationNotAvailable": t(
    "common.map.geolocateControlLocationNotAvailable",
  ),
  "LogoControl.Title": t("common.map.logoControlTitle"),
  "Map.Title": t("common.map.mapTitle"),
  "NavigationControl.ResetBearing": t(
    "common.map.navigationControlResetBearing",
  ),
  "NavigationControl.ZoomIn": t("common.map.navigationControlZoomIn"),
  "NavigationControl.ZoomOut": t("common.map.navigationControlZoomOut"),
  "ScrollZoomBlocker.CtrlMessage": t("common.map.scrollZoomBlockerCtrlMessage"),
  "ScrollZoomBlocker.CmdMessage": t("common.map.scrollZoomBlockerCmdMessage"),
  "TouchPanBlocker.Message": t("common.map.touchPanBlockerMessage"),
});

/* UPDATE */

export const updateMapStyle = (
  map: Map,
  mapType: MapType,
  locale: Locale,
  theme?: string,
): void => {
  removeRouteAnimation();
  map.setStyle(getMapStyle(mapType, locale, theme), {
    localFontFamily: undefined,
    localIdeographFontFamily: undefined,
    diff: false,
  });
};
